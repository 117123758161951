import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import '../styles/Popup.css';

const VideoPlayer = ({ src }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showIcon, setShowIcon] = useState(true); // New state for icon visibility
    const videoRef = useRef(null);

    const togglePlayPause = () => {
        const video = videoRef.current;
        if (video.paused) {
            video.play();
            setIsPlaying(true);
            setShowIcon(true);
            setTimeout(() => setShowIcon(false), 350); // Hide icon after 1s
        } else {
            video.pause();
            setIsPlaying(false);
            setShowIcon(true);
        }
    };

    // Optionally, reset the icon visibility if the video is paused manually
    useEffect(() => {
        const video = videoRef.current;

        const handlePause = () => {
            setIsPlaying(false);
            setShowIcon(true);
        };

        video.addEventListener('pause', handlePause);

        return () => {
            video.removeEventListener('pause', handlePause);
        };
    }, []);

    return (
        <div className="video-container">
            <video
                ref={videoRef}
                src={src}
                muted
                // autoPlay
                playsInline
                preload="auto"
                className="video"
                onClick={togglePlayPause}
            />
            <div
                className={`icon-container ${showIcon ? 'visible' : 'hidden'}`}
                onClick={togglePlayPause}
            >
                <FontAwesomeIcon
                    icon={isPlaying ? faPause : faPlay}
                    className={`play-pause-icon ${isPlaying ? 'pause-icon' : 'play-icon'}`}
                />
            </div>
        </div>
    );
};

export default VideoPlayer;

