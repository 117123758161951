import React, { useState, useEffect, useRef } from 'react';
import '../styles/PopupPages.css';

const PopupContent = ({ pages }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const contentRef = useRef(null);
    let startX = 0;

    useEffect(() => {
        contentRef.current.focus();
    }, []);

    const handleNext = () => {
        setCurrentPage((prevPage) => (prevPage + 1) % pages.length);
    };

    const handlePrev = () => {
        setCurrentPage((prevPage) => (prevPage - 1 + pages.length) % pages.length);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
            handleNext();
        } else if (event.key === 'ArrowLeft') {
            handlePrev();
        }
    };

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        const endX = e.changedTouches[0].clientX;
        if (startX > endX + 50) {
            handleNext();
        } else if (startX < endX - 50) {
            handlePrev();
        }
    };

    return (
        <div
            ref={contentRef}
            onKeyDown={handleKeyDown}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            tabIndex="0"
        >
            <div
                className="pages-container"
                style={{ transform: `translateX(-${currentPage * 100}%)` }}
            >
                {pages.map((page, index) => (
                    <div className="page" key={index}>
                        {page}
                    </div>
                ))}
            </div>
            <div className="popup-footer">
                <button className="nav-button left" onClick={handlePrev}>
                    <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="white" d="M13.0607 23.0607C12.4749 23.6464 11.5251 23.6464 10.9393 23.0607L0.939341 13.0607C0.353555 12.4749 0.353555 11.5251 0.93934 10.9393L10.9393 0.939341C11.5251 0.353554 12.4749 0.353554 13.0607 0.939341C13.6464 1.52513 13.6464 2.47487 13.0607 3.06066L4.12132 12L13.0607 20.9393C13.6464 21.5251 13.6464 22.4749 13.0607 23.0607Z"/>
                    </svg>
                </button>
                <div className="page-counter">
                    Page {currentPage + 1}/{pages.length}
                </div>
                <button className="nav-button right" onClick={handleNext}>
                    <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="white" d="M0.93934 0.93934C1.52513 0.353553 2.47487 0.353553 3.06066 0.93934L13.0607 10.9393C13.6464 11.5251 13.6464 12.4749 13.0607 13.0607L3.06066 23.0607C2.47487 23.6464 1.52513 23.6464 0.93934 23.0607C0.353553 22.4749 0.353553 21.5251 0.93934 20.9393L9.87868 12L0.93934 3.06066C0.353553 2.47487 0.353553 1.52513 0.93934 0.93934Z"/>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default PopupContent;
