// Popup.jsx
import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import closeButton from "../images/close-button.png"
import '../styles/Popup.css';

const Popup = ({isOpen, onClose, isAnimating, children}) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={`overlay ${isAnimating ? 'fadeOut' : 'fadeIn'}`} onClick={onClose}>
            <div className="popup-margin">
            <div className={`popup-wrapper-wrapper ${isAnimating ? 'slideDown' : 'slideUp'}`}
                 onClick={(e) => e.stopPropagation()}>
                <div className="popup-wrapper">
                    <div className="popup">
                        <button className="close-btn" onClick={onClose}>
                            <img src={closeButton}/>
                        </button>
                        <div className="popup-content">{children}</div>
                    </div>
                </div>
            </div>
            </div>
        </div>,
        document.body
    );
};

export default Popup;
