import React, { useRef } from 'react';
import '../styles/GifSwitcher.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const GifSwitcher = ({ gifSrc, title }) => {
    const gifRef = useRef(null);

    return (
        <div
            className="gif-container"
        >
            <img ref={gifRef} src={gifSrc} alt="GIF" className="gif-image" />
            <div className="gif-overlay">
                <FontAwesomeIcon
                    icon={faEye}
                    className="gif-overlay-icon"
                />
                <p>{title}</p>
            </div>
        </div>
    );
};

export default GifSwitcher;
